import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selectAdvantage", "selectDisadvantage", "requiredField", "successMessage", "reviewStar"]

  select(event) {
    event.target.classList.toggle("box--selected")
    this.getFormData()
  }

  updateRating(event) {
    this.reviewStarTargets.forEach((el) => {
      if (el.attributes.value.value <= event.currentTarget.attributes.value.value) {
        el.classList.add("full")
      } else {
        el.classList.remove("full")
      }
    })
  }

  validate(event) {
    event.preventDefault()

    this.validateRequiredFields()
    if (this.valid) {
      this.submitForm()
    }
  }

  private

  submitForm() {
    const formData = this.getFormData()
    fetch(this.data.get("formUrl"), {
      body: formData,
      method: 'POST',
      dataType: 'script',
      credentials: "include",
      headers: {
        "X-CSRF-Token": this.getCsrfToken()
      },
    })
    .then((response) => {
      const status = response.status
      console.log(status)
      this.resetFields(status)
    })
  }

  resetFields(status) {
    const message = status === 200 ? "Review verzonden!" : "Er ging iets mis"

    this.successMessageTarget.innerText = message
    this.requiredFieldTargets.forEach((el) => {
      el.value = ""
    })
    this.selectAdvantageTargets.forEach((el) => {
      el.classList.remove("box--selected")
    })
    this.selectDisadvantageTargets.forEach((el) => {
      el.classList.remove("box--selected")
    })
  }

  get valid() {
    return this.data.valid
  }

  set valid(value) {
    this.data.valid = value
  }

  validateRequiredFields() {
    let anyEmpty = false
    this.requiredFieldTargets.forEach((el) => {
      let isEmpty = el.value === ""
      el.classList.toggle("is-invalid", isEmpty)
      el.classList.toggle("is-valid", !isEmpty)

      if (isEmpty) {
        anyEmpty = isEmpty
      }
    })
    this.valid = !anyEmpty
  }

  addSelectedBoxes(formData) {
    this.selectAdvantageTargets.forEach((el) => {
      if (el.classList.contains("box--selected")) {
        formData.append("review[advantage_ids][]", el.id)
      }
    })
    this.selectDisadvantageTargets.forEach((el) => {
      if (el.classList.contains("box--selected")) {
        formData.append("review[disadvantage_ids][]", el.id)
      }
    })
    return formData
  }

  getFullStars() {
    return this.reviewStarTargets.filter(el => el.classList.contains("full")).length
  }

  addScore(formData) {
    return formData.append("review[rating]", this.getFullStars())
  }

  getFormData() {
    const reviewForm = document.getElementById("review_form")
    const formData = new FormData(reviewForm)
    // for (var pair of formData.entries()) {
    //   console.log(pair[0]+ ', ' + pair[1]);
    // }
    this.addSelectedBoxes(formData)
    this.addScore(formData)
    return formData
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "viewScore" ]

  getScore(event) {
    const target = this.viewScoreTarget
    target.innerText = event.currentTarget.value
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['preview', 'input']

  connect() {
    // const url = this.data.get('url')
    this.displayHtml()

    // fetch(`/admin/blog/${url}`, { headers: { accept: "application/json" }})
    //   .then(response => response.json())
    //   .then((data) => {
    //     console.log(data)
    //   })
  }

  displayHtml() {
    this.previewTarget.contentDocument.documentElement.innerHTML = this.updateHtml()
  }

  updateHtml() {
    let html = this.rawHtml
    // const variables = html.match(/(?<={{{)(.*?)(?=}}})/g)
    variables.forEach((variable) => {
      // const input = this.inputTargets.find(input => input.name == variable)
      // if (input) html = html.replace(`{{{${variable}}}}`, input.value)
    })
    return html
  }

  get rawHtml() {
    // fetch html
    return this.data.get('rawHtml')
  }
}

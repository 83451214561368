import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['checkbox']

  submit() {
    const form = document.getElementById('filter-menu-form')
    const target = document.getElementById('compare_results')
    target.innerHTML = `<div class="spinner-border text-primary"></div>`

    setTimeout(() => {
      Rails.fire(form, 'submit')
    }, 500)
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['result']

  // whenever this PR gets merged, this should work (https://github.com/hotwired/stimulus/pull/249) via parameters.
  sort(event) {
    const link = event.currentTarget
    const baseUrl = '/admin/providers'
    const sortParams = JSON.parse(link.dataset.searchProvidersSortParam)
    const sortParamsString = `?sort_by=${sortParams.sort_by}&sort_direction=${sortParams.sort_direction}`
    const headers = { headers: { accept: 'application/json' } }
    fetch(baseUrl + sortParamsString, headers)
      .then(response => response.json())
      .then(data => {
        const newSortParams = this.getNewSortParams(sortParams)
        link.dataset.searchProvidersSortParam = newSortParams
        this.resultTarget.innerHTML = this.renderProviders(data.providers).join('')
        window.history.pushState(null, 'providers', sortParamsString)
      })
  }

  renderProviders = providers => {
    return providers.map(provider => {
      return `
        <tr class="${provider.deleted_at ? 'deleted' : ''}">
          <td><input type="checkbox" name="providers" id="providers" value="1"></td>
          <td scope="row">${provider.id}</td>
          <td><a href="/admin/providers/${provider.name}">${
        provider.name
      }</a></td>
          <td>${provider.status}</td>
          <td>${provider.af_status === "to_be_requested" ? "TBR" : provider.af_status}</td>
          <td>${
            new Date(provider.updated_at).toLocaleString().split(',')[0]
          }</td>
          <td>0</td>
          <td>0</td>
          <td class="actions d-flex justify-content-around">
            <a href="/admin/providers/${provider.name}/edit">Edit</a>
            <a rel="nofollow" data-method="delete" href="/admin/providers/${
              provider.name
            }">
              ${this.trashCanImage()}
            </a>
          </td>
        </tr>
      `
    })
  }

  trashCanImage = () => {
    return `
    <svg width="13px" height="21px" viewBox="0 0 13 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Web-Responsive-Ten-Grid" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="dashboard" transform="translate(-1266.000000, -310.000000)" stroke="#979797">
          <g id="Group-12" transform="translate(1266.000000, 310.000000)">
              <path d="M0.5,5.5 L1.5,20.5" id="Line-13" stroke-linecap="square"></path>
              <path d="M11.5,5.5 L12.5,20.5" id="Line-13-Copy" stroke-linecap="square" transform="translate(12.000000, 13.000000) scale(-1, 1) translate(-12.000000, -13.000000) "></path>
              <path d="M1.5,20.5 L11.5,20.5" id="Line-14" stroke-linecap="square"></path>
              <path d="M0.5,5.5 L12.5,5.5" id="Line-15" stroke-linecap="square"></path>
              <rect id="Rectangle" fill="#D8D8D8" x="0.5" y="1.5" width="12" height="2"></rect>
              <rect id="Rectangle" fill="#D8D8D8" x="3.5" y="0.5" width="6" height="1"></rect>
              <polygon id="Path-13" fill="#D8D8D8" points="0.5 5.5 12.5 5.5 11.5 20.5 1.5 20.5"></polygon>
              <path d="M3.5,8.5 L4.5,17.5" id="Line-16" fill="#D8D8D8"></path>
              <path d="M8.5,8.5 L9.5,17.5" id="Line-16-Copy" fill="#D8D8D8" transform="translate(9.000000, 13.000000) scale(-1, 1) translate(-9.000000, -13.000000) "></path>
              <path d="M6.5,8.5 L6.5,17.5" id="Line-17" fill="#D8D8D8"></path>
          </g>
        </g>
      </g>
    </svg>
    `
  }

  getNewSortParams = (currentSortParams) => {
    const direction = currentSortParams.sort_direction === 'desc' ? 'asc' : 'desc'
    return `{"sort_by":"${currentSortParams.sort_by}","sort_direction":"${direction}"}`
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['consentButton']

  connect() {
    // console.log('hi')
    // const localStorage = window.localStorage
    // const consent = localStorage.getItem('swguruCookies')
    // console.log({ consent })
    // if (consent) {
    //   // console.log('Consent!')
    // } else {
    //   console.log('show popup!')
    //   // this.showConsentDiv()
    // }
  }

  showConsentDiv() {
    this.consentButtonTarget.style.display = 'block'
  }

  confirm() {
    console.log('confirm!')
    console.log(this.consentButtonTarget.remove())
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "categoryBox", "selectDisadvantage", "requiredField", "successMessage" ]

  toggleActive(e) {
    this.categoryBoxTarget.classList.toggle("active")
    this.categoryBoxTarget.parentNode.classList.toggle("active")
  }

  removeActive(e) {
    if (this.element.contains(e.target) === false) {
      this.categoryBoxTarget.classList.remove("active")
      this.categoryBoxTarget.parentNode.classList.remove("active")
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  scrollTo(e) {
    e.preventDefault()
    const scrollTarget = document.querySelector(e.target.hash)

    window.scroll({
      top: scrollTarget.offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }

  connect() {
    const nav = this.element
    const topOfNav = nav.offsetTop

    window.addEventListener('scroll', () => {
      if (window.scrollY >= topOfNav) {
        nav.classList.add('scrolled')
        document.body.style.paddingTop = nav.offsetHeight + 'px'
      } else {
        document.body.style.paddingTop = 0
        nav.classList.remove('scrolled')
      }
    })
  }
}

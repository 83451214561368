import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "requiredField", "successMessage" ]

  validate(event) {
    event.preventDefault()

    this.validateRequiredFields()
    if (this.valid) {
      this.submitForm()
    }
  }


  private

  submitForm() {
    const formData = this.getFormData()
    fetch(this.data.get("formUrl"), {
      body: formData,
      method: 'POST',
      dataType: 'script',
      credentials: "include",
      headers: {
        "X-CSRF-Token": this.getCsrfToken()
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        this.showError()
      } else {
        this.resetFields()
      }
    })
  }

  resetFields() {
    this.successMessageTarget.innerText = "Bericht verzonden!"
    this.requiredFieldTargets.forEach((el) => {
      el.value = ""
      el.classList.remove("is-valid")
    })
  }

  showError() {
    const messageElement = this.successMessageTarget
    messageElement.classList.add("red")
    messageElement.innerText = "Er ging iets mis"
  }

  get valid() {
    return this.data.valid
  }

  set valid(value) {
    this.data.valid = value
  }

  validateRequiredFields() {
    let anyEmpty = false
    this.requiredFieldTargets.forEach((el) => {
      let isEmpty = el.value === ""
      el.classList.toggle("is-invalid", isEmpty)
      el.classList.toggle("is-valid", !isEmpty)

      if (isEmpty) {
        anyEmpty = isEmpty
      }
    })
    this.valid = !anyEmpty
  }

  getFormData() {
    const contactForm = document.getElementById("contact_form")
    return new FormData(contactForm)
  }

  getCsrfToken() {
    return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }
}

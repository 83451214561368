import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'categoryOption',
    'typeOption',
    'currentCategory',
    'currentType'
  ]

  get currentCategory() {
    return this.data.get('currentCategory')
  }

  get currentType() {
    return this.data.get('currentType')
  }

  set currentCategory(value) {
    this.data.set('currentCategory', value)
    this.currentCategoryTarget.innerText = value
    this.resetCategoryValues()
  }

  set currentType(value) {
    this.data.set('currentType', value)
    this.currentTypeTarget.innerText = value
    this.resetTypeValues()
  }

  selectCategory(event) {
    event.preventDefault()
    const newValue = event.target.text
    this.data.set('url', event.target.dataset.urlName)
    this.currentCategory = newValue
  }

  selectType(event) {
    event.preventDefault()
    const newValue = event.target.text
    this.currentType = newValue
  }

  resetCategoryValues() {
    this.categoryOptionTargets.forEach(el => {
      el.classList.toggle(
        'option--current',
        this.currentCategory === el.innerText
      )
      if (this.currentCategory === el.innerText) {
        el.innerHTML = `${el.innerText}<span class='checkmark'><div class='checkmark_stem'></div><div class='checkmark_kick'></div></span>`
      } else {
        el.innerHTML = el.innerText
      }
    })
  }

  resetTypeValues() {
    this.typeOptionTargets.forEach(el => {
      el.classList.toggle('option--current', this.currentType === el.innerText)
      if (this.currentType === el.innerText) {
        el.innerHTML = `${el.innerText}<span class='checkmark'><div class='checkmark_stem'></div><div class='checkmark_kick'></div></span>`
      } else {
        el.innerHTML = el.innerText
      }
    })
  }

  submitSearchCategory() {
    if (this.currentCategory === null || this.currentType === null) {
      document.querySelectorAll('.search-element').forEach(el => {
        el.style.borderColor = '#f5a640'
      })
    } else {
      const location = `${window.location.origin}/${this.data.get(
        'url'
      )}?types=${this.currentType.split(' ')}`

      window.location.href = location
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  checkMetaTitle(event) {
    const minLength = 50
    const maxLength = 60
    this.validateInputLength(event.currentTarget, minLength, maxLength)
  }

  checkMetaDescription(event) {
    const minLength = 120
    const maxLength = 158
    this.validateInputLength(event.currentTarget, minLength, maxLength)
  }

  setCharCount = (label, count) => {
    const text = label.innerText 
    const newText = text.replace(/(\d+)/, count.toString())
    label.innerText = newText
  }

  setInvalid = (element) => {
    element.classList.add("is-invalid")
    element.classList.remove("is-valid")
  }

  setValid = (element) => {
    element.classList.add("is-valid")
    element.classList.remove("is-invalid")
  }

  validateInputLength = (element, minLength, maxLength) => {
    const length = element.value.length
    const label = element.previousSibling
    this.setCharCount(label, length)
    if (length < minLength || length > maxLength) {
      this.setInvalid(element)
    } else {
      this.setValid(element)
    }
  }
}
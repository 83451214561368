import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    const postBody = document.querySelector(".post-body")
    const titleElements = postBody.querySelectorAll("h2")
    const titles = []
    titleElements.forEach((el) => {
      const title = {
        text: el.innerText,
        parameterize: el.id
      }
      titles.push(title)
    })

    // this.element.innerHTML = ''
    titles.forEach((title, i) => {
      const node = this.htmlToElement(`<a class="list-group-item list-group-item-action" href="#${title.parameterize}"><span>${i+2}.</span>${title.text}</a>`)
      this.element.appendChild(node)
    })
  }

  htmlToElement(html) {
    const template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }
}

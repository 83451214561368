import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selectProviders", "selectionBox", "selectedProvider" ]

  connect() {
    this.load()
  }

  select(event) {
    const selection = JSON.parse(sessionStorage.getItem("selection")) || []

    if (event.target.classList.contains("provider--selected")) {
      event.target.classList.remove("provider--selected")
      const indexToRemove = selection.findIndex((element) => element.id_token == JSON.parse(event.currentTarget.dataset.provider).id_token)
      selection.splice(indexToRemove, 1)
      this.setSelectionBox(selection)
      sessionStorage.setItem("selection", JSON.stringify(selection))
      this.amount--
    } else {
      if (this.amount >= 3) { return }
      event.target.classList.add("provider--selected")
      selection.push(JSON.parse(event.target.dataset.provider))
      this.setSelectionBox(selection)
      sessionStorage.setItem("selection", JSON.stringify(selection))
      this.amount++
    }

    this.hideOrShowContainer()
  }

  get amount() {
    return sessionStorage.getItem("amount")
  }

  set amount(value) {
    sessionStorage.setItem("amount", value)
  }

  compareSelection(event) {
    event.preventDefault()

    this.validateSelection()
    if (this.valid) {
      this.submitSelection()
    }
  }

  removeProvider(event) {
    const selection = JSON.parse(sessionStorage.getItem("selection")) || []
    const indexToRemove = selection.findIndex((element) => element.id_token == JSON.parse(event.currentTarget.dataset.provider).id_token)

    selection.splice(indexToRemove, 1)
    sessionStorage.setItem("selection", JSON.stringify(selection))
    this.amount--

    this.setSelectionBox(selection)
    this.setSelectBoxes(selection)
    this.hideOrShowContainer()
  }

  private

  load() {
    const selection = JSON.parse(sessionStorage.getItem("selection")) || []

    this.hideOrShowContainer()
    this.setSelectBoxes(selection)
    this.setSelectionBox(selection)
  }

  hideOrShowContainer() {
    const selectBoxProviderContainer = document.getElementById("select_box_providers_container")

    if (this.amount == 0 || this.amount == undefined) {
      selectBoxProviderContainer.style.visibility = "hidden";
    } else {
      selectBoxProviderContainer.style.visibility = "visible";
    }
  }

  setSelectBoxes(selection) {
    this.selectProvidersTargets.forEach(target => {
      if (selection.some(provider => provider.id_token == JSON.parse(target.dataset.provider).id_token)) {
        target.classList.add("provider--selected")
      } else {
        target.classList.remove("provider--selected")
      }
    })
  }

  setSelectionBox(selection) {
    const selectedProviders = selection.map(provider => {
      const htmlObject = document.createElement('div')
      htmlObject.innerHTML = `
        <div data-compare-target="selectedProvider" class="compare-selection-provider d-flex mb-2">
          <div class="compare-selection-provider-image-wrapper">
            <img src="${provider.image_url}">
          </div>
          <div class="compare-selection-provider-details d-flex flex-column justify-content-center pl-1">
            <span>${provider.name}</span>
          </div>
          <div class="d-flex align-items-center">
            <svg id="closeButtonSvg" data-action="click->compare#removeProvider" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8Z" stroke="black" stroke-width="0.5"/>
              <path d="M12.2667 4.2666L4.26672 12.2666M12.2667 4.2666L4.26672 12.2666" stroke="black" stroke-width="0.5" stroke-linejoin="round"/>
              <path d="M4.26672 4.2666L12.2667 12.2666M4.26672 4.2666L12.2667 12.2666" stroke="black" stroke-width="0.5" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      `
      htmlObject.getElementsByTagName("svg")[0].setAttribute('data-provider', JSON.stringify(provider))
      return htmlObject
    })

    this.selectionBoxTarget.innerHTML = ""
    selectedProviders.forEach((provider) => this.selectionBoxTarget.append(provider))
  }

  submitSelection() {
    const providerIdTokens = this.getProviderIdTokens()
    Turbolinks.visit(`${this.data.get("url")}?providers=${providerIdTokens.join()}`)
  }

  validateSelection() {
    let selectAmount = this.amount

    if (selectAmount > 1 && selectAmount <= 3) {
      this.valid = true
    } else {
      this.valid = false
    }
  }

  getProviderIdTokens() {
    const providerIdTokens = []
    this.selectProvidersTargets.forEach((el) => {
      if (el.classList.contains("provider--selected")) {
        providerIdTokens.push(el.id)
      }
    })
    return providerIdTokens
  }
}

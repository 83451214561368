import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "box", "sign" ]

  initialize() {
    this.showCurrentBox()
    this.showCurrentTarget()
  }

  reduce() {
    this.volume = ""
  }

  enlarge(event) {
    this.volume = event.currentTarget.dataset.value
  }

  toggleSign(el) {
    if (el.parentNode.parentNode.className.includes("current")) {
      el.innerHTML = "-";
    } else {
      el.innerHTML = "+";
    }
  }

  showCurrentBox() {
    this.boxTargets.forEach((el) => {
      el.classList.toggle("box--current", this.volume == el.dataset.value)
    })
  }

  showCurrentTarget() {
    this.signTargets.forEach((el, i) => {
      this.toggleSign(el)
    })
  }

  get volume() {
    return this.data.get("volume")
  }

  set volume(value) {
    this.data.set("volume", value)
    this.showCurrentBox()
    this.showCurrentTarget()
  }
}
